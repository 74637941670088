<!-- 玩法介绍 -->
<template>
  <div class="">
    <numheader></numheader>

    <div class="clear"></div>
  </div>
  <router-view />
  <wffooter></wffooter>
</template>

<script>
  //import '../js/font.js' //js文件引入，注意路径
  //import {myfun} from '../js/test.js' //js文件引入，注意路径
  //import Introduction from '@/views/Introduction.vue'
  //import api from '@/js/method'
  import numheader from "@/components/wanfa/wf-header.vue";
  import wffooter from "@/components/Footer.vue";
  export default {
    name: "Number-select",
    components: {
      numheader,
      wffooter,
    },
    data() {
      // 数据源
      return {};
    },
    mounted() { },
    computed: {
      // sd() {
      //     return this.$store.state.sd;
      // },
    },
    methods: {
      // 函数体部分，js的主要逻辑控制
    },
    props: {},
  };
</script>

<style scoped>
  /* @import './swiper.css'; */
  .clear {
    clear: both;
  }
</style>